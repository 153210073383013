@media (max-width:1199px) {
  .search-container {
    display: none;
  }
}

@media (max-width:767px) {
  .brand .name {
    display: none;
  }
  #sidebar {
    width: 100%;
    z-index: 2;
  }
  .un-list {
    width: 100%;
  }
  #themes-content {
    max-height: fit-content !important;
  }
  .themes-list {
    height: fit-content !important;
  }
  .about-text {
    margin: 5px;
  }
  .news-wrap {
    width: 100%;
    /* margin: 5px; */
  }
  .title-h1 {
    font-size: 2em;
  }
  .xlsx-viewer {
    width: 100%;
    z-index: 1;
  }
  #hideBTN {
    display: block;
  }
  .mobile-warn {
    display: block;
  }
  /* .rs-table-cell, .rs-table-cell-content {
    width: 100px !important;
    left: 200px;
  } */
}