/* || GENERAL STYLES */

/* html, body, #app, #app>div {
  height: 100%
} */
.w-56 {
  width: 56px;
}
.w-64 {
  width: 64px;
}
.w-256 {
  width: 256px;
}
.h-60 {
  height: 60px;
}
.align-left {
  align-self: 'flex-start';
}
.align-right {
  align-self: 'flex-end';
}

.full {
  width: 100%;
  height: 100%;
}
.b-0 {
  border-radius: none;
}
.clear-p-m {
  padding: 0px;
  margin: 0px;
}
.m-5 {
  margin: 5px 5px;
}
.m-10 {
  margin: 10px 10px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-t-12 {
  margin-top: 12px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-r-15 {
  margin-right: 15px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-l-15 {
  margin-left: 15px;
}


.p-0 {
  padding: 0px;
}
.p-10{
  padding: 10px;
}
.p-15 {
  padding: 15px;
}

.radio-group {
  padding: 8px 12px;
  display: inline-block;
  vertical-align: middle;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

/* || Typography */
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-underline {
  text-decoration: underline;
}
.line-height-46 {
  line-height: 46px;
}
.f-24 {
  font-size: 24px;
}
.f-w-600 {
  font-weight: 600;
}
.slim-text {
  font-size: 0.666em;
  color: #97969B;
  font-weight: lighter;
}
.title {
  white-space: nowrap;
  font-size: 1.2em;
}
.centered {
  justify-content: center;
  align-items: center;
}

/* || MAP STYLE */
.leaflet-container {
  /* width: 100wh; 
  height: 100vh; */
}
.leaflet-control-geosearch {
  color:#7D7C7A;
  /* color:#323232; */
}
.z-600 {
  z-index: 600;
}

#modalmap { 
  height: 400px; 
}
#refreshButton {
  position: absolute;
  top: 12px;
  left: 80px;
  padding: 10px;
  z-index: 500;
  border-radius: 5px;
  background-color: #0f131a;
}
#mapButton {
  position: absolute;
  top: 160px;
  left: 10px;
  padding: 10px;
  z-index: 500;
  border-radius: 5px;
  background-color: #0f131a;
}
.topOnMap {
  /* /* position: absolute; */
  top: 10px;
  left: 80px;
  padding: 5px;
  /* z-index: 500; */
  cursor:pointer;
}

/* || SIDEBAR HEADER STYLES */
.top-user-info {
  padding: 6px 0 0 15px;
  background-color: #3C3F43;
  /*  background: '#26B2F5'; */
  /* bordercolor: '#26B2F5'; */
  color: #fff
}
.lang-flags {
  border-radius: 50%;
  margin: auto ;
  padding: 0 0 0 0;
  height: 20px;
  width: 20px;
  text-align: center;
}
/* Placeholder for Avatar */
.img-placeholder {
  width: 50px;
  height: 50px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}
.img-circle-placeholder {
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: auto;
}

.preview {
  width: 60px;
  height: 60px;
  margin: 4px 4px 4px 10px;
  background-color: #555;
}
.preview-details {
  width: 500px;
  height: auto;
  background-position: center;
  background-size: cover;
}

/* || Design elemetns highlight */
.color-highlight {
  color: #E3AF34
  /* color: #3C3F43 */
}
.bg-highlight {
  background-color: #E3AF34
}
.attention-color {
  color: #ffb300;
}
.signIn{
  margin: 0 15px 15px 0
}
.blacked {
  background-color: #000;
}
/* || IMG in Tables and Lists */
.cell-img-preview {
  width: 40px;
  height: 40px;
  background-color: #f5f5f5;
  border-radius: 20px;
  margin-top: 2px;
  overflow: hidden;
  display: inline-block;
}

/* || Cart  */
.cart-item-centered {
  justify-content: center;
  align-items: center;
  height: 60px;
}
.cart-title-price {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  font-weight: 600;
  font-size: 1.2em;
}

.d-sign-clickable{
	cursor: pointer;	
}
.d-sign-clickable:hover{

	color:skyblue;
	transition: color 0.3s linear;
}
.rs-list-hover{
	max-height: calc(100% - 65px);
}
.d-side-new{
    height:calc(100vh - 56px) !important;
}
.rs-sidenav-subtle{
    height: calc(100vh - 56px) !important;
    overflow-y:auto;
}
.rs-panel-body-fill {
    height:99%;
}
.new-scroll{
	height: calc(100vh - 213px);
}
.d-order-table-panel{
    overflow-y:auto;
}
::-webkit-scrollbar {
    position: absolute;
    background-color: #a4a9b3;
    border-radius: 4px;
	width:10px;
}
::-webkit-scrollbar:hover {
	width: 15px;
}
::-webkit-scrollbar-track {
  background: #292d33;
}
::-webkit-scrollbar-thumb {
  background: #a4a9b3;
  border-radius: 4px;
}
.order-cell{
  overflow-y: auto;
  display: flex;
  height: 100%;
  flex-direction: column;
}
.order-cell > div{
  margin-top: 6px;
  padding: 5px;
  min-width: 100%;
  border: solid gray 1px;
  font-weight: 500;
  font-size: 16px;
}
.infolist{
  float: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: fit-content;
}
.info-entry{
  float: right;
  font-weight: bolder;
}
.rs-modal-wrapper{
  background: rgba(0, 0, 0, 0.671);
}
.docs-wrapper {
  background-color: white;
  height: 100vh;
  color:#000;
}
.docs-header {
  position: fixed;
  display: flex;
  height: 120px;
  left: 0px;
  right: 0px;
  top: 0px;
  padding: 10px;
  /* box-shadow: 0px 0px 40px rgba(0, 0, 0); */
  border-bottom: rgb(133, 133, 133) 2px solid ;
}
.docs-nav {
  position: fixed;
  /* top: 120px; */
  border-right: rgb(133, 133, 133) 2px solid ;
  height: 100vh;
  width: 20%;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow-y: auto;
}
.docs-main {
  position: relative;
  /* top: 120px; */
  width: 80%;
  left: 20%;
  max-height: 100vh;
  overflow-y: scroll;
  padding: 10px;
}
.docs-node {
  color: #000;
  display: block;
  border-radius: 15px;
  padding: 15px;
  margin: 10px;
  font-size: medium;
}
.docs-node:active, .docs-node:hover, .docs-node:focus, .docs-active-node  {
  color: #6aa8ff;
  background-color: #f1f7ff;
  font-weight: bold;
  box-shadow: 0px 4px 4px rgba(0, 0, 0);
  text-decoration: none;
}
.hidden-map {
  visibility: hidden;
}
.vis-map {
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
}
.new-link-element h5 {
  font-size: small;
  font-weight:700;
}
.fields-list-main > h4, .dropdown-content > h4 {
  margin: auto;
  display: flex;
  justify-content: center;
}
.datamodal-wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-height: 100vh;
  overflow: auto;
}
.footer-sd {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  z-index: 1001;
  background-color: #cacaca;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000000;
}
.header-sd {
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  z-index: 1002;
  background-color: #cacaca;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #000000;
}
.header-sd-toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-direction: row-reverse;
}
.icon-tool-sd {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: large;
  border: 1px solid #000000;
  padding: 3px;
  user-select: none;
  cursor: pointer;
  text-transform: uppercase;
  /* font-size: small; */
}
.icon-tool-sd:hover {
  color: #000000;
  background: #ffffff;
}
.map-main-sd {
  position: fixed;
  width: 85%;
  height: calc(100vh - 100px);
  top: 50px;
  right: 0;
}
#map {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.navbar-side-new {
  position: fixed;
  top: 50px;
  left: 0;
  height: 100%;
  background-color: #949494;
  z-index: 1000;
  display: block;
  width: 15%;
  float: left;
  -webkit-transition: all 300ms ease-out 0s;
  -o-transition: all 300ms ease-out 0s;
  transition: all 300ms ease-out 0s;
}
.new-link-element {
  font-size: 40px;
  line-height: 1;
  padding: 5px 0 5px 12px;
  margin: 0;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #503434;
  margin: 3px auto;
  font-family: arial;
  width: 95%;
  display: flex;
  align-items: center;
  justify-items: center;
  font-family: 'Inter';
  transition: all 0.5s;
  user-select: none;
  text-align: left;
}
.side-head {
  font-size: 25px;
  line-height: 1;
  padding: 12px 0 12px 12px;
  margin: 0;
  text-transform: uppercase;
  color: #fff;
}
.sd-icon {
  height: 37px;
  padding: 0 10px;
  cursor: pointer;
  user-select: none;
  border-left: 1px solid black;
}
.sd-control-text {
  margin: 0;
  font-size: 16px;
  cursor: pointer;
  user-select: none;
  text-transform:uppercase;
  color: rgb(107, 129, 143);
  height: 100%;
  display: block;
  padding: 14px 5px;
}
.sd-control-text:hover {
  color: black;
  background: white;
}

.pointer {
  cursor: pointer;
}

.leaflet-bar a {
  color: #2980b9;
  width: 41px !important;
  height: 41px !important;
  font-size: 2.5em !important;
  font-weight: normal;
  line-height: 37px !important;
  text-align: center;
  text-indent: 0;
  border: 1px solid #8394a0;
  margin: -1px;
  display: block;
  fill: #2980b9;
  stroke: #2980b9;
  /* filter: brightness(0) saturate(100%) invert(47%) sepia(22%) saturate(1651%) hue-rotate(161deg) brightness(88%) contrast(86%); */
}
.leaflet-bar a:hover {
  color: #FFFFFF;
  background-color: #2980b9;
}
.leaflet-draw-toolbar a {
  background-size: cover;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-polygon {
  background-position: -39px -1px;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-rectangle {
  background-position: -78px -1px;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-marker {
  background-position: -160px -1px;
}
.logo-div {
  display: flex;
  align-items: center;
}

.logo-div > p{
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
}
.title-h1 {
  text-align: center;
}
.news-wrap {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 auto;
}
.news-item {
  width: 100%;
  border-radius: 20px;
  border: 2px solid black;
  background-color: aliceblue;
  max-height: 500px;
  overflow: auto;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  text-align: justify;
}
.news-item h4 {
  font-size: 25px;
  text-align: center;
  width: 100%;
  display: block;
}
body, #root {
  height: 100%;
}
h2 {
  font-weight: normal !important;
}

.about-text {
  white-space: pre-wrap;
  margin: 20px 20%;
}

.about-text > p {
  text-indent: 30px;
  text-align: justify;
  font-size: 1.2em;
}

.xlsx-viewer {
  position: fixed;
  width: calc(100% - 330px);
  /* height: 250px; */
  z-index: 1000000;
  bottom: 0;
  right: 0;
  background: white;
}

.bass {
  color: white;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.bass .selectable {
  text-decoration: underline;
  cursor: pointer;
}
.bass .selectable:hover {
  color: #e6ff00;
}
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}

#gallery {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  /* padding: 20px; */
}

#gallery > div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

#gallery > div > div {
  cursor: pointer;
  transform: scale(1);
  width: 150px;
  margin: 10px;
}

#gallery > div > div:hover {
  transition: all 0.3s;
  transform: scale(1.2);
}
.gal-modal.rs-modal {
  width: fit-content !important;
  max-width: 100vw;
}
.gal-modal.rs-modal, .gal-modal .rs-modal-dialog {
  margin: auto !important;
}
/* .auth-modal {
  margin: 30vh auto !important;
} */
.rs-modal-wrapper{
  display: flex;
  align-items: center;
}

.mobile-warn {
  display: none;
  color: white;
  text-align: center;
  font-size: 30px;
}

.DraftEditor-editorContainer {
  height: 400px;
}

.home {
  margin: 20px;
  position: absolute;
}

.title-wrapper {
  display: flex;
  justify-content: center;
}

.langbtn {
  position: absolute;
  margin: 20px 65px;
}

.db-farm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.db-farm > img, .half-image {
  width: 500px;
  max-width: 100vw;
  margin-top: 20px;
}

.db-farm > p {
  margin-bottom: 20px;
}

.db-farm > p, .db-farm > img {
  text-align: center;
}

.half-image {
  display: flex;
}
.half-image img {
  width: 50%;
}